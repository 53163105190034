<template>
  <div>
    <div class="">
      <b-modal
        v-model="show"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        lazy
        static
        @hidden="$emit('close')"
      >
        <div v-if="orderSelect">
          <b-row class="match-height">
            <b-col
              cols="6"
            >
              <b-form-group
                label="Geste commercial"
                class="text-uppercase"
              >
                <b-form-input v-model="orderSelect.gesteCo" />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group
                label="Description"
                class="text-uppercase"
              >
                <b-form-textarea v-model="orderSelect.description" />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group
                label="Frais de retour (€)"
                class="text-uppercase"
              >
                <b-form-input v-model="orderSelect.frais" />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group
                label="Montant du remboursement (€)"
                class="text-uppercase"
              >
                <b-form-input v-model="orderSelect.montantRefund" />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group
                label="Type de retour"
                class="text-uppercase"
              >
                <p>{{ orderSelect.returnType }}</p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col cols="6">
              <b-form-group
                label="Statut"
                class="text-uppercase"
              >
                <v-select
                  v-model="orderSelect.status"
                  :options="['En attente', 'Retour à créer', 'Envoyé au client', 'A Rembourser', 'Hors délai', 'Remboursée', 'Annuler', 'Echange']"
                  transition=""
                />
              </b-form-group>
            </b-col>
            <b-col v-if="orderSelect.carrier === 'Autres'" cols="6">
              <b-form-group
                label="Lien de suivi retour"
                class="text-uppercase"
              >
                <b-form-input v-model="orderSelect.tracking_link" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Date de retour prévu par le client"
                class="text-uppercase"
              >
                <flat-pickr
                  v-model="orderSelect.dateReturn"
                  placeholder="Date"
                  class="form-control mr-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            size="sm"
            variant="primary mt-2 mr-1"
            :disabled="loading"
            @click="save(orderSelect)"
          >
            <span class="ml-25 align-middle">Enregistrer</span>
          </b-button>
        </div>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="orders"
          responsive
          :fields="tableColumns"
          show-empty
          :busy="busy"
          empty-text="Aucune commande trouvée"
        >

          <!-- Column: User -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(order)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :variant="`light-${resolveUserStatusVariant(data.item.order.shipping.type)}`"
                  :to="{ name: 'commandeDetail', params: { id: data.item.order.number } }"
                />
              </template>
              <b-link
                :to="{ name: 'commandeDetail', params: { id: data.item.order.number } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.order.shipping.first_name }} {{ data.item.order.shipping.last_name }}
              </b-link>
              <small class="text-muted">{{ data.item.order.number }}</small>
            </b-media>
          </template>

          <!-- Column: Status -->
          <template #cell(price)="data">
            <b-badge
              pill
              class="text-capitalize bg-secondary bg-lighten-3"
            >
              {{ data.item.order.number.slice(0, 2) === 'DK' ? (data.item.order.total * 0.13).toFixed(2) : data.item.order.total }} €
            </b-badge>
          </template>

          <template #cell(status)="data">
            <div class="d-flex cursor-pointer" @click="openModal(data.item)">
              <b-badge
                pill
                class="text-capitalize"
                :class="`bg-${resolveStatusIconColor(data.item.status)}`"
              >
                {{ data.item.status }}
              </b-badge>
              <feather-icon
                icon="EditIcon"
                class="ml-1 text-primary"
                size="16"
              />
            </div>
          </template>

          <template #cell(created_at)="data">
            <span>
              {{ $moment(data.item.created_at).format('DD-MM-YYYY') }}
            </span>
            <b-badge v-if="$moment(data.item.created_at).isBefore($moment().subtract(30, 'days')) && (!data.item.lastEvent || data.item.lastEvent.code === 'DR1')"
              pill
              class="text-capitalize bg-danger"
            >
              HORS DÉLAI
            </b-badge>
            <b-badge v-if="data.item.dateReturn"
              pill
              class="text-capitalize bg-success"
              :class="`bg-${$moment(data.item.dateReturn).isBefore($moment()) ? 'danger' : 'success'}`"
            >
              Date de retour
              <br>
              prévu par le client:
              <br>
              {{ $moment(data.item.dateReturn).format('DD/MM/YYYY') }}
            </b-badge>
          </template>

          <template #cell(system)="data">
            <span>
              {{ $moment(data.item.system.dateShipping).format('DD-MM-YYYY') }}
            </span>
          </template>

          <template #cell(lastEvent)="data">
            <span v-if="data.item.lastEvent">
              {{ data.item.lastEvent.label.slice(0, 45) }}... ({{ $moment(data.item.lastEvent.date).format('DD-MM-YYYY') }})
            </span>
          </template>

          <template #cell(montantRefund)="data">
            <span>
                <b-badge
                pill
                class="text-capitalize bg-success"
              >
              {{ Number(data.item.montantRefund || 0) + Number(data.item.frais || 0) }}€
              </b-badge>
            </span>
          </template>

          <template #cell(product)="data">
            <span
              v-b-tooltip.hover
              :title="data.item.name"
              variant="gradient-primary"
            >
              {{ data.item.name }} ({{ data.item.sku }})
            </span>
          </template>

          <template #cell(country)="data">
            <img
              style="width: 30px;"
              :src="require(`@/assets/images/flags/${imageCountry(data.item.order.number.slice(0, 2))}`)"
            >
          </template>
          <template #cell(_creator)="data">
            <div class="text-nowrap">
              {{ data.item._creator ? data.item._creator.firstName : 'Inconnu' }}
            </div>
          </template>
          <template #cell(carrier)="data">
            <div v-if="data.item.carrier === 'Colissimo'" class="d-flex">
              <a
                :href="data.item.tracking_link"
                target="_blank"
                class="text-nowrap"
              >
                <img
                  style="width: 60px;"
                  :src="require(`@/assets/images/logistic/colissimo.png`)"
                >
              </a>
              <a
                v-if="Array.isArray(data.item.url)"
                :href="data.item.url.find(u => u.includes('return'))"
                target="_blank"
                class="text-nowrap"
              >
                <img
                  style="width: 50px;"
                  :src="require(`@/assets/images/logistic/label-icon.png`)"
                >
              </a>
              <a
                v-else
                :href="data.item.url"
                target="_blank"
                class="text-nowrap"
              >
                <img
                  style="width: 50px;"
                  :src="require(`@/assets/images/logistic/label-icon.png`)"
                >
              </a>
            </div>
            <div v-else>
              <p>Autres</p>
              <a v-if="data.item.tracking_link && data.item.tracking_link !== ''" :href="data.item.tracking_link" target="_blank">TRACKING</a>
            </div>
          </template>

        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BTable, BMedia,
  BBadge, BAvatar, BLink, VBTooltip, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      default: null,
    },
    busy: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      tableColumns: [],
      orderSelect: null,
      show: false,
      loading: false,
      avatarText,
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: 'setColumn',
    },
  },
  async mounted() {
    console.log('change filter')
    const tableColumns = [
      { key: 'order', label: 'Client', sortable: true },
      { key: 'status', label: 'Statut', sortable: true },
      { key: 'montantRefund', label: 'Montant', sortable: true },
      { key: 'lastEvent', label: 'Tracking', sortable: true },
      { key: 'created_at', label: 'Date', sortable: true },
      { key: 'product', label: 'Produits', sortable: true },
      { key: 'country', label: 'Pays', sortable: true },
      { key: 'carrier', label: 'Carrier', sortable: true },
      { key: '_creator', label: 'Créateur', sortable: true },
    ]
    this.tableColumns = tableColumns
  },
  methods: {
    openModal(order) {
      this.orderSelect = order
      this.show = true
    },
    async save() {
      try {
        this.loading = true
        await this.$http.post(`/admin/shippypro/${this.orderSelect.order.number}/status`, { ...this.orderSelect })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien enregistré',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        this.orderSelect = null
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async setColumn() {
      if (this.filter?.$and?.map(f => Object.entries(f))?.map(f => f[0])?.filter(f => f[0] === 'order.status' && f[1] === 'waiting-delivery')?.length) {
        const tableColumns = [
          { key: 'order', label: 'Client', sortable: true },
          { key: 'price', label: 'Montant', sortable: true },
          { key: 'status', label: 'Statut', sortable: true },
          { key: 'system', label: 'Date de livr.', sortable: true },
          { key: 'date', label: "Date d'achat", sortable: true },
          { key: 'product', label: 'Produits', sortable: true },
          { key: 'country', label: 'Pays', sortable: true },
        ]
        this.tableColumns = tableColumns
      }
    },
    resolveUserStatusVariant(type) {
      if (type === 'admin') return 'success'
      if (type === 'user') return 'secondary'
      return 'primary'
    },
    imageCountry(type) {
      if (type === 'FR') return 'france.svg'
      if (type === 'DE') return 'allemagne.svg'
      if (type === 'ES') return 'espagne.svg'
      if (type === 'IT') return 'italie.svg'
      if (type === 'PT') return 'portugal.svg'
      if (type === 'EN') return 'en.png'
      if (type === 'NL') return 'netherlands.png'
      if (type === 'DK') return 'denmark.png'
      return 'france.svg'
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'info'
    },
    resolveStatusIconColor(status) {
      switch (status) {
        case 'Remboursée':
          return 'success'
        case 'Retour à créer':
          return 'danger'
        case 'Envoyé au client':
          return 'warning'
        case 'En attente':
          return 'danger'
        default:
          return 'info'
      }
    },
    resolveStatusIcon(status) {
      switch (status) {
        case 'processing':
          return 'ToolIcon'
        case 'pending':
          return 'ClockIcon'
        case 'on-hold':
          return 'MinusCircleIcon'
        case 'completed':
          return 'CalendarIcon'
        case 'failed':
          return 'AlertCircleIcon'
        case 'cancelled':
          return 'XCircleIcon'
        case 'refunded':
          return 'RepeatIcon'
        default:
          return false
      }
    },
    resolveStatusTranslation(status) {
      switch (status) {
        case 'processing':
          return 'En fabrication'
        case 'pending':
          return 'En attente de paiement'
        case 'on-hold':
          return 'En attente'
        case 'sended':
        case 'completed':
          return 'Expédiée'
        case 'failed':
          return 'Echouée'
        case 'cancelled':
          return 'Annulée'
        case 'refunded':
          return 'Remboursée'
        case 'rembourse-partiel':
          return 'Remboursée partiellement'
        default:
          return status
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
