<template>

  <div>
    <Search @change="setFilter" />
    <!-- <download-csv
      :data="orders.map(o => ({commande: o.number, first_name: o.shipping.first_name, last_name: o.shipping.last_name, email: o.billing.email, tracking: o.tracking, logistic: o.logistic, shipper: o.shipper, sku: o.sku, date_dispatch: o.date, date_order: o.date_created }))"
      name="logistic.csv"
    > -->
    <b-button
      class="mb-2"
      variant="warning"
      @click="uploadList"
    >
      Télécharger
    </b-button>
    <!-- </download-csv> -->
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <List :orders="orders" :busy="loading" />
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="list"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip,
  BRow, BCol, BPagination, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-unresolved
import List from '@components/orders/ReturnList.vue'
import Search from '@components/orders/SearchReturn.vue'
// import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BButton,
    List,
    Search,
    // 'download-csv': JsonCSV,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      orders: [],
      loading: false,
      tableColumns: [],
      avatarText,
      currentPage: 10,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 25,
      filter: {},
      searchQuery: '',
    }
  },
  methods: {
    setFilter(filter, search) {
      this.filter = filter
      this.searchQuery = search || ''
      this.list(1)
    },
    async list(e) {
      this.loading = true
      this.currentPage = e
      const option = {
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      }
      const search = this.searchQuery
      const {
        orders,
        totalPages,
        totalItems,
        itemsPerPage,
      } = await this.$http.get('/admin/shippypro/list', { params: { ...option, filter: this.filter, search } })
      this.orders = orders
      this.totalPages = totalPages
      this.totalItems = totalItems
      this.itemsPerPage = itemsPerPage
      this.loading = false
    },
    async uploadList() {
      try {
        this.loadingButton = true
        const search = this.searchQuery
        const filter = { ...this.filter }
        const data = await this.$http({
          url: '/upload-list-dispatch',
          method: 'POST',
          responseType: 'blob',
          params: { filter, search },
        })
        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'export.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      } catch (err) {
        console.log(err)
      } finally {
        this.loadingButton = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
