<template>
  <div>
    <b-card title="Vous pouvez rechercher les logistics directement ici.">
      <b-row>
        <b-col
          cols="6"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="mt-2 mt-md-0"
        >
          <v-select
            v-model="logisticien"
            placeholder="Fabricant"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="logisticOptions"
            @input="filter"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="logistics && logistics.length"
          cols="2"
        >
          <download-csv
            :data="logistics.map(l => ({sku: l.sku, product: l.product, type: l.alert ? 'A COMMANDER' : 'STOCK SUFFISANT', stock: (l.agediss + l.colisland) - l.waiting, logisticien: l.logisticien, 'M-1': l.quantityOrderM1, 'M-3': l.quantityOrder, 'M-6': l.quantityOrderM6, 'Y - 1 / M + 6': l.quantityOrderOld, 'Y - 1 / M - 3': l.quantityOrderOldSame, 'Restant à livrer': l.quantiteRemaining, 'En fabrication': l.onProcessing, 'En Waiting Delivery': l.waiting }))"
            name="logistic.csv"
          >
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          small
          selectable
          :items="logistics"
          :fields="fields"
        >
          <!-- <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template> -->
          <template #cell(sku)="data">
            <p>{{ data.item.sku }} <br><span>{{ data.item.product }}</span></p>
          </template>
          <template #cell(alert)="data">
            <b-badge :variant="`light-${data.item.alert ? 'danger' : 'success'}`">
              {{ data.item.alert ? 'CHECK' : 'OK' }}
            </b-badge>
          </template>
          <template #cell(total)="data">
            <b-badge :variant="`light-${data.item.total < 5 ? 'danger' : 'success'}`">
              {{ (data.item.agediss + data.item.colisland) - data.item.waiting - data.item.quantityOrderProcessing }}
            </b-badge>
          </template>
          <template #cell(productsOrderW6)="data">
            <span>
              {{ data.item.productsOrderW6 }}
            </span>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BBadge,
  BTable,
  VBPopover,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    BInputGroup,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseLogistics: [],
      logistics: [],
      products: [],
      categories: [],
      logistic: {},
      conditionalProducts: '',
      conditionalCategories: '',
      logisticOptions: ['Dequecker', 'GommaGomma', 'Valrupt', 'Samarfil', 'Sabbe', 'Radi', 'Homespirit', 'Drouault', 'Cogal', 'Abeil'],
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'sku', sortable: true }, { key: 'logisticien', sortable: true }, { key: 'quantityOrderM1', label: 'M-1', sortable: true }, { key: 'quantityOrderM6', label: 'M-6', sortable: true }, { key: 'quantityOrder', label: 'M-3', sortable: true }, { key: 'quantityOrderOldSame', label: 'Y-1/M-3', sortable: true }, { key: 'quantityOrderOld', label: 'Y-1/M+6', sortable: true }, { key: 'total', label: 'Stock', sortable: true }, { key: 'quantiteRemaining', label: 'Restant à livrer', sortable: true }, { key: 'productsOrderW6', label: 'Prévisionnel ( 3m )', sortable: true }, { key: 'alert', label: 'Statut', sortable: true }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getLogistics()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getLogistics() {
      try {
        const {
          logistics,
        } = await this.$http.get('/admin/logistic/order')
        this.logistics = logistics
        this.baseLogistics = logistics
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if ((!this.code || this.code === '') && (!this.logisticien || this.logisticien === '')) {
        this.logistics = this.baseLogistics
      } else {
        const value = this.code
        if (this.logisticien) {
          this.logistics = this.baseLogistics.filter(logistic => logistic.sku?.toLowerCase().includes(value.toLowerCase()) && this.logisticien === logistic.logisticien)
        } else {
          this.logistics = this.baseLogistics.filter(logistic => logistic.sku?.toLowerCase().includes(value.toLowerCase()))
        }
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
