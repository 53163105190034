import { render, staticRenderFns } from "./SearchReturn.vue?vue&type=template&id=b85d57a4&scoped=true&"
import script from "./SearchReturn.vue?vue&type=script&lang=js&"
export * from "./SearchReturn.vue?vue&type=script&lang=js&"
import style0 from "./SearchReturn.vue?vue&type=style&index=0&id=b85d57a4&lang=scss&scoped=true&"
import style1 from "./SearchReturn.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b85d57a4",
  null
  
)

export default component.exports